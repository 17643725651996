<template>
  <div>
    <b-row>
      <b-col
        lg="6"
        md="8"
        sm="12"
      >
        <b-card title="Enter your Mailchimp API Key below.">
          <div class="mb-1">
            You'll find your <a
              target="_blank"
              href="https://admin.mailchimp.com/account/api/"
            >API key here</a>.
          </div>
          <b-row class="mb-1">
            <b-col md="12">
              <div
                v-if="mailchimpSettings && mailchimpSettings.api_key"
                class="current-api-key mb-2"
              >
                <span>Current API key:</span>
                {{ mailchimpSettings.api_key }}
              </div>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="4"
                content-cols-sm
                content-cols-lg="8"
                label="Set new API Key:"
                label-for="api_key"
              >
                <b-form-input
                  v-model="APIkey"
                  name="api_key"
                  required
                  :state="APIKeyFieldValidation.state"
                  placeholder="Enter API Key here ex.: b4765easd12d1adcbasc8b-us1"
                />
                <b-form-invalid-feedback>
                  {{ APIKeyFieldValidation.message }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-card-footer class="pl-0 pr-0 pb-0">
            <b-row md="6">
              <b-col>
                <b-button
                  variant="primary"
                  :disabled="isSubmitting"
                  @click="submitNewApiKey"
                >Connect to
                  Mailchimp
                </b-button>
              </b-col>
            </b-row>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCardFooter,
  BCol,
  BFormCheckbox,
  BFormInput,
  BFormGroup,
  BLink,
  BRow,
  BTab,
  BTable,
  BTabs,
  BFormInvalidFeedback,
  BFormValidFeedback,
} from 'bootstrap-vue'
import { cloneDeep } from 'lodash'
import store from '@/store'
import AccountService from '@/services/AccountService'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { handleValidationError } from '@/utils/helpers'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BCard,
    BCardText,
    BCardFooter,
    BFormGroup,
    BFormCheckbox,
    BLink,
    BTabs,
    BTab,
    BTable,
    BFormInput,
    BFormInvalidFeedback,
    BFormValidFeedback,
  },
  data() {
    return {
      APIkey: '',
      showCurrentApiKey: false,
      mailchimpSettings: [],
      APIKeyFieldValidation: {
        state: null,
        message: '',
      },
      isSubmitting: false,
    }
  },
  created() {
    this.getApiKeyFromStore()
  },
  methods: {
    async submitNewApiKey() {
      this.isSubmitting = true
      const self = this
      if (self.APIkey === '') {
        self.APIKeyFieldValidation.state = false
        return
      }
      self.APIKeyFieldValidation.state = true
      try {
        await AccountService.updateMailchimpIntegrationSettings({ api_key: self.APIkey })
          .then(response => {
            if (response.status === 200) {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success',
                  icon: 'BellIcon',
                  text: 'Item updated successfully',
                  variant: 'success',
                },
              })
              store.dispatch('auth/setAuthUser', response.data.data)
                .then(() => { this.$router.push({ path: '/integrations/mailchimp' }) })
            }
          })
      } catch (e) {
        console.error(e)
      } finally {
        this.isSubmitting = false
        self.APIKeyFieldValidation.state = null
        self.APIKeyFieldValidation.message = ''
      }
    },
    getApiKeyFromStore() {
      this.mailchimpSettings = cloneDeep(store.getters['auth/account'].mail_chimp_integration)
    },
  },
}
</script>
<style scoped>
.current-api-key{
  width:100%;
  padding:30px 20px 20px 20px;
  background:#F0F0F0;
  position:relative;
}
.current-api-key > span{
  position:absolute;
  top:0;
  left:0;
  font-size: 0.7rem;
  background: #7366f0;
  color: #FFF;
  padding: 2px 10px;
}
</style>
